<template>
  <div class="modal-mask" v-if="isVisible" @click.self="close">
    <div class="modal-container">
      <div class="modal-header">
        <slot name="header">
          <span class="default-title">Reminder</span>
        </slot>
      </div>
      <div class="modal-body">
        <slot>
          <!-- 显示传递的 alert 消息 -->
          <span class="modal-message-alert">{{ message }}</span>
        </slot>
      </div>
      <div class="modal-footer">
        <slot name="footer">
          <button class="modal-default-button" @click="close">OK</button>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AlertComponent",
  data() {
    return {
      isVisible: false,
      message: "",
    };
  },
  methods: {
    open(message) {
      this.message = message;
      this.isVisible = true;
    },
    close() {
      this.isVisible = false;
    },
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 99998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-container {
  color: black;
  background-color: #fff;
  border: 1px solid #e1e4e8;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 400px;
  max-width: 90%;
}

.modal-header {
  margin-bottom: 10px;
}

.default-title {
  font-size: 20px;
  font-weight: bold;
}

.modal-message-alert {
  font-size: 14px;
  text-align: justify;
}

.modal-footer {
  padding-top: 20px;
  text-align: right;
}

.modal-default-button {
  font-size: 12px;
  background-color: white;
  color: #1d2e79;
  border: 2px solid #1d2e79;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 20px;
}

.modal-default-button:hover {
  background-color: #ddd;
}

.modal-default-button:active {
  background-color: #aaa;
}
</style>
