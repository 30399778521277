<template>
  <!-- 桌面端 -->
  <div v-if="isDesktop" id="app">
    <!-- 页眉 -->
    <HeaderComponent />

    <!-- 路由视图，用于显示不同的页面内容 -->
    <div class="content">
      <router-view />
    </div>

    <!-- 页脚 -->
    <FooterComponent />
  </div>

  <!-- 移动端 -->
  <div v-else id="mobile-app">
    <MobileView />
  </div>

  <!-- 全局的 AlertComponent -->
  <AlertComponent ref="globalAlert" />
</template>

<script>
import HeaderComponent from "./components/HeaderComponent.vue";
import FooterComponent from "./components/FooterComponent.vue";
import AlertComponent from "./components/AlertComponent.vue";
import MobileView from './views/MobileView/index.vue';

export default {
  name: "App",
  components: {
    HeaderComponent,
    FooterComponent,
    AlertComponent,
    MobileView,
  },
  data() {
    return {
      isDesktop: true,
      windowWidth: window.innerWidth,
    };
  },
  methods: {
    // 判断设备类型
    checkDeviceType() {
      const userAgent = navigator.userAgent.toLowerCase();
      // 判断是否为移动设备或者窗口宽度小于574
      const isMobile = /mobile|android|iphone|ipad|phone|tablet/i.test(userAgent) || window.innerWidth < 574;
      this.isDesktop = !isMobile; // 如果不是移动设备且窗口宽度大于等于574，则认为是桌面设备
    },

    // 更新窗口宽度
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
  },
  mounted() {
    // 初始化时检查设备类型
    this.checkDeviceType();
    this.updateWindowWidth();

    // 监听窗口大小变化
    window.addEventListener("resize", this.updateWindowWidth);

    // 重写全局的 alert 方法，使它调用自定义的 AlertComponent
    window.alert = (message) => {
      this.$refs.globalAlert.open(message);
    };
  },
  beforeUnmount() {
    // 移除窗口大小变化的监听器
    window.removeEventListener("resize", this.updateWindowWidth);
  },
};
</script>

<style>
/* 确保整个页面占满窗口 */
html,
body,
#app {
  height: 100%;
  margin: 0;
  padding: 0;
}

#mobile-app {
  width: 100%;
}

/* 使用 flexbox 布局确保页眉在顶部，页脚在底部，内容占据剩余空间 */
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1; /* 让内容部分占据剩余空间 */
  padding: 20px; /* 添加一些内边距 */
}

/* 全局的基础样式 */
body {
  font-family: Arial, sans-serif;
}
</style>
