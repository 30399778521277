import { createRouter, createWebHistory } from "vue-router";
import store from '@/store';

// 路由配置
const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/HomeView"), // 懒加载
  },
  {
    path: "/pricing",
    name: "Pricing",
    component: () => import("@/views/PaymentView"),
  },
  {
    path: "/my-resume",
    name: "My Resume",
    component: () => import("@/views/ResumeManagementView"),
  },
  {
    path: "/about",
    name: "About",
    component: () => import("@/views/AboutView"),
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () => import("@/views/PrivacyPolicyView"),
  },
  {
    path: "/terms-of-service",
    name: "TermsOfService",
    component: () => import("@/views/TermsOfServiceView"),
  },
  // // 先不实用job match功能
  // {
  //   path: "/job-match/:id", // 动态路由匹配
  //   name: "JobMatch",
  //   component: () => import("@/views/JobMatchView"), // 对应的视图组件
  //   props: true, // 允许将路由参数作为组件的prop传递
  // },
  {
    path: "/:pathMatch(.*)*", // 捕获所有未匹配的路由
    name: "NotFound",
    component: () => import("@/views/NotFoundView"),
  },
  // // 旧版edit
  // {
  //   path: "/resume-polish/:id",
  //   name: "ResumePolish",
  //   component: () => import("@/views/ResumePolishView"),
  //   props: true
  // },
  {
    path: '/resume-workflow/:id', // 修改路径
    name: 'ResumeWorkflow',
    component: () => import('@/views/ResumeWorkFlow/index.vue'),
    children: [
      {
        path: 'check',
        name: 'ResumeCheck',
        component: () => import('@/views/ResumeWorkFlow/components/ResumeCheck.vue')
      },
      {
        path: 'scoring',
        name: 'ScoringEvaluation',
        component: () => import('@/views/ResumeWorkFlow/components/Scoring.vue')
      },
      {
        path: 'rebuild',
        name: 'AISuperRebuild',
        component: () => import('@/views/ResumeWorkFlow/components/AISuperRebuild.vue')
      }
    ]
  },
  {
    path: "/scoring/:id",
    name: "Scoring",
    component: () => import("@/views/ScoringView"),
    props: true,
  },
];

// 创建路由实例
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL), // 使用HTML5模式的历史记录
  routes,
  scrollBehavior() {
    return { top: 0 };
  }
});

// after you leave the ResumePolish page, set the current resume to null
router.afterEach((to, from) => {
  if (from.name === 'ResumePolish') {
    store.commit('resume/SET_CURRENT_RESUME_LOCAL', store.state.currentResume);
  }
});

// 导出路由实例
export default router;
