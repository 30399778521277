<template>
  <div class="container">
    <!-- Header -->
    <div class="header">
      <img src="@/assets/logo.png" alt="Mima Logo" class="logo">
      <h1>Mima</h1>
    </div>

    <!-- Welcome Text -->
    <div class="welcome-text">
      <h2>Welcome!</h2>
      <p>
        You've successfully registered.<br>
        Thanks for choosing us!
      </p>
    </div>

    <!-- Card Section -->
    <div class="card">
      <img src="../assets/computer_icon.png" alt="PC Icon" class="card-icon">
      <p>
        To use our advanced resume features including Resume Analysis and Custom Resume Generation,
        please visit our <b>desktop (PC) site</b>.
      </p>
    </div>

    <!-- Main Content -->
    <div class="main-content">
      <h3>Visit PC and Enjoy</h3>
      <ul class="feature-list">
        <li class="feature-item">
          <img src="../assets/100_icon.png" alt="Icon" class="feature-icon">
          <p>Rebuild a Verified Outstanding Resume</p>
        </li>
        <li class="feature-item">
          <img src="../assets/search_icon.png" alt="Icon" class="feature-icon">
          <p>Gain In-Depth Resume Analysis</p>
        </li>
        <li class="feature-item">
          <img src="../assets/calendar_icon.png" alt="Icon" class="feature-icon">
          <p>Generate Custom Resume for Each Job</p>
        </li>
        <li class="feature-item">
          <img src="../assets/bulb_icon.png" alt="Icon" class="feature-icon">
          <p>Get a Job Insight Based on Your Resume</p>
        </li>
        <li class="feature-item">
          <img src="../assets/network_icon.png" alt="Icon" class="feature-icon">
          <p>Find Useful Contact in a Industry</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "AfterLoginComponent",
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
}

body {
  background-color: #f4f4f4;
  padding: 20px;
}

.container {
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
  width: 95%;
  padding: 5px;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.header .logo {
  width: 40px;
  margin-right: 10px;
}

.header h1 {
  font-size: 24px;
  font-weight: bold;
}

.welcome-text {
  text-align: left;
  margin-bottom: 20px;
}

.welcome-text h2 {
  font-size: 18px;
  font-weight: normal;
}

.welcome-text h2 span {
  font-weight: bold;
  font-size: 20px;
}

.card {
  background-color: #d3effd;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
}

.card .card-icon {
  width: 50px;
  margin-bottom: 10px;
}

.card p {
  font-size: 14px;
  color: #333;
  line-height: 1.5;
  text-align: left;
}

.card p b {
  font-weight: bold;
}

.main-content h3 {
  font-size: 18px;
  margin-bottom: 20px;
}

.feature-list {
  list-style: none;
  padding: 0;
}

.feature-item {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
}

.feature-item .feature-icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.feature-item p {
  margin: 0;
  font-size: 16px;
  text-align: left;
  color: #333;
}
@media (max-width: 768px) {
  .greeting p {
    font-size: 1.8em;
  }
}
</style>
