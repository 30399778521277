<template>
  <footer class="footer">
    <div class="footer-section">
      <span>&copy; 2024 AIJobTech. All rights reserved.</span>
    </div>
    <div class="footer-section">
      <router-link to="/privacy-policy" class="footer-link">
        Privacy Policy
      </router-link>
      <router-link to="/terms-of-service" class="footer-link">
        Terms of Service
      </router-link>
      <a href="mailto:aijobtech@gmail.com" class="footer-link">Contact Us</a>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>

<style scoped>
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  background-color: #1d2e79;
  color: white;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  user-select: none;
  font-size: 16px;
}

.footer-section {
  display: flex;
  align-items: center;
}

.footer-section span {
  margin: 0 15px;
}

.footer-link {
  cursor: pointer;
  color: white;
  text-decoration: none;
  transition: all 0.3s ease;
  padding: 0 10px;
}

.footer-link:hover {
  text-decoration: underline;
}

.footer-link:active {
  transform: scale(0.95);
}
</style>
