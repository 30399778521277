<template>
  <div class="modal-mask" v-if="visible" @click.self="close">
    <div class="modal-container">
      <div class="modal-header">
        <slot name="header">
          <h2>Register a New Account</h2>
        </slot>
      </div>
      <div class="modal-body">
        <slot>
          <form @submit.prevent="handleRegister">
            <div class="input-group">
              <label for="register-email">Email</label>
              <input
                type="email"
                id="register-email"
                v-model="email"
                required
              />
            </div>

            <div class="input-group">
              <label for="register-password">Password</label>
              <div class="password-container">
                <input
                  :type="showPassword ? 'text' : 'password'"
                  id="register-password"
                  v-model="password"
                  required
                />
                <span
                  class="toggle-password"
                  @click="togglePassword('register-password')"
                >
                  <i
                    :class="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"
                  ></i>
                </span>
              </div>
              <p class="password-hint">
                8-16 characters, including uppercase, lowercase, number, and
                special character.
              </p>
            </div>

            <div class="input-group">
              <label for="confirm-password">Confirm Password</label>
              <div class="password-container">
                <input
                  :type="showConfirmPassword ? 'text' : 'password'"
                  id="confirm-password"
                  v-model="confirmPassword"
                  required
                />
                <span
                  class="toggle-password"
                  @click="togglePassword('confirm-password')"
                >
                  <i
                    :class="
                      showConfirmPassword ? 'fa fa-eye' : 'fa fa-eye-slash'
                    "
                  ></i>
                </span>
              </div>
            </div>

            <div class="input-group">
              <label for="verification-code">Verification Code</label>
              <div class="verification-container">
                <input
                  type="text"
                  id="verification-code"
                  v-model="verificationCode"
                  required
                  class="verification-input"
                />
                <button
                  @click="sendVerificationCode"
                  :disabled="disableResend"
                  class="resend-button"
                >
                  {{ resendButtonText }}
                </button>
              </div>
            </div>

            <div class="input-group">
              <label for="invite-code">Invitation Code</label>
              <input
                type="text"
                id="invite-code"
                v-model="invitationCode"
                placeholder="Optional"
                class="invite-input"
              />
            </div>

            <div class="terms-group">
              <input
                type="checkbox"
                id="agree-terms"
                v-model="agreeTerms"
                class="checkbox"
                required
              />
              <label for="agree-terms">
                I accept the
                <a href="/terms-of-service" target="_blank" class="footer-link">
                  Terms of Service
                </a>
                and
                <a href="/privacy-policy" target="_blank" class="footer-link">
                  Privacy Policy
                </a>
                .
              </label>
            </div>

            <div v-if="errorMessage" class="error-message">
              {{ errorMessage }}
            </div>

            <button type="submit" class="register-button">Register</button>
          </form>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { API_BASE_URL } from "@/config";

export default {
  name: "RegistrationComponent",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    emits: [close],
  },
  data() {
    return {
      email: "",
      password: "",
      confirmPassword: "",
      verificationCode: "",
      invitationCode: "",
      agreeTerms: false,
      resendTimeout: 60,
      disableResend: false,
      resendButtonText: "Send Code",
      showPassword: false,
      showConfirmPassword: false,
      errorMessage: null,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    handleRegister() {
      if (this.password !== this.confirmPassword) {
        this.errorMessage = "Passwords do not match.";
        return;
      }
      if (!this.agreeTerms) {
        this.errorMessage = "You must agree to the terms and conditions.";
        return;
      }
      let loader = this.$loading.show();
      axios
        .post(`${API_BASE_URL}/user/register`, {
          email: this.email,
          password: this.password,
          verificationCode: this.verificationCode,
          invitationCode: this.invitationCode || null,
        })
        .then((response) => {
          alert(response.data.message || "Registration successful.");
          this.close();
        })
        .catch((error) => {
          this.errorMessage =
            error.response.data.error ||
            "Registration failed due to an unexpected error.";
        })
        .finally(() => {
          loader.hide();
        });
    },
    sendVerificationCode() {
      if (this.disableResend) return;

      this.disableResend = true;
      this.resendButtonText = `${this.resendTimeout} seconds`;

      axios
        .post(`${API_BASE_URL}/user/send-verification-code`, {
          email: this.email,
        })
        .then(() => {
          alert("Verification code sent. Please check your email.");
          this.startResendCountdown();
        })
        .catch(() => {
          this.errorMessage = "Failed to send verification code.";
          this.disableResend = false;
          this.resendButtonText = "Send Code";
        });
    },
    startResendCountdown() {
      const interval = setInterval(() => {
        this.resendTimeout -= 1;
        this.resendButtonText = `${this.resendTimeout} seconds`;

        if (this.resendTimeout <= 0) {
          clearInterval(interval);
          this.resendTimeout = 60;
          this.disableResend = false;
          this.resendButtonText = "Send Code";
        }
      }, 1000);
    },
    togglePassword(field) {
      if (field === "register-password") {
        this.showPassword = !this.showPassword;
      } else if (field === "confirm-password") {
        this.showConfirmPassword = !this.showConfirmPassword;
      }
    },
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-container {
  color: black;
  background-color: #fff;
  border: 1px solid #e1e4e8;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 20px 30px 20px;
  width: 400px;
}

.modal-header {
  margin-bottom: 20px;
  text-align: center;
  position: relative;
}

.modal-header h2 {
  font-size: 20px;
  font-weight: bold;
  color: black;
}

.modal-body {
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.input-group {
  margin-bottom: 10px;
}

.input-group label {
  display: block;
  font-size: 14px;
  color: black;
  margin-bottom: 5px;
}

.input-group input {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #e1e4e8;
  border-radius: 4px;
  box-sizing: border-box;
  position: relative;
}

.password-container {
  display: flex;
  align-items: center;
}

.password-hint {
  font-size: 11px;
  color: #888;
}

.toggle-password {
  cursor: pointer;
  margin-left: 10px;
  user-select: none;
}

.register-button {
  font-size: 12px;
  width: 100%;
  padding: 10px;
  background-color: white;
  color: #1d2e79;
  border: 2px solid #1d2e79;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 10px;
  margin-bottom: 7px;
}

.register-button:disabled {
  color: grey;
  border-color: grey;
  cursor: not-allowed;
}

.register-button:not(:disabled):hover {
  color: white;
  background-color: #1d2e79 /* 鼠标悬停时背景颜色 */
}

.register-button:not(:disabled):active {
  color: white;
  background-color: #2a3daa; /* 按钮被按下时背景颜色 */
}

.error-message {
  color: red;
  font-size: 13px;
  margin-top: 10px;
}

.verification-container {
  display: flex;
  align-items: center;
}

.verification-input {
  margin-right: 10px;
}

.verification-input,
.resend-button {
  flex-grow: 1;
}

.resend-button {
  flex-basis: 30%; /* 按钮占据剩余空间 */
  white-space: nowrap; /* 防止按钮文字换行 */
  color: #1d2e79;
  background-color: white;
  border: 2px solid #1d2e79;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
}

.resend-button:disabled {
  color: grey;
  border-color: grey;
  cursor: not-allowed;
}

.resend-button:not(:disabled):hover {
  color: white;
  background-color: #1d2e79 /* 鼠标悬停时背景颜色 */
}

.resend-button:not(:disabled):active {
  color: white;
  background-color: #2a3daa; /* 按钮被按下时背景颜色 */
}

.terms-group {
  font-size: 14px;
  display: flex;
  align-items: center;
}

.terms-group label a {
  color: #1d2e79;
}

.terms-group label a:hover {
  text-decoration: underline;
}

.checkbox {
  margin-right: 10px;
}
</style>
