<template>
  <div>
    <BeforeLoginComponent v-if="!userLoggedIn" />
    <AfterLoginComponent v-else />
  </div>
</template>

<script>
import { mapState } from "vuex";
import BeforeLoginComponent from "./components/BeforeLoginComponent.vue";
import AfterLoginComponent from "./components/AfterLoginComponent.vue";

export default {
  name: "MobileView",
  components: {
    BeforeLoginComponent,
    AfterLoginComponent,
  },
  computed: {
    ...mapState(["userLoggedIn"]),
  },
};
</script>

<style scoped>
</style>
